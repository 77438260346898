import React, { useEffect } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import localization from "moment/locale/fr";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import MomentUtils from "@date-io/moment";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import BookingListLogic from "./../TabLogics/BookingListLogic";
import Button from "components/CustomButtons/Button.js";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	MenuItem,
	Select,
} from "@material-ui/core";

const textStyles = makeStyles({
	formControlRoot: {
		fontFamily: "futura",
		color: "#6E6FA9",
		borderRadius: "7px",
		"& label.Mui-focused": {
			color: "#6E6FA9",
		},
	},
	inputLabelRoot: {
		color: "#6E6FA9",
		fontFamily: "futura",
		"&.focused": {
			color: "#6E6FA9",
		},
	},
});

const paginationStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "#6E6FA9",
		},
		"& .Mui-selected": {
			backgroundColor: "#6E6FA9",
			color: "#E7E6ED",
		},
	},
}));

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function BookingListView(props){
	const textClasses = textStyles();
	const classes = useStyles();
	const paginationClasses = paginationStyles();
	
	const {
		myself,
		startDate,
		endDate,
		setEndDate,
		setStartDate,
		refreshData,
		toggleQuery,
		query,
		ressources,
		ressource,
		toggleSelectedRessource,
		allPeriods,
		period,
		statusBookings,
		updateBookingStatusFilter,
		updatePeriodFilter,
		bookingStatus,
		bookingsList,
		page,
		handlePagination,
		showDialog,
		setShowDialog,
		refundBooking,
		bookingToRefund,
		setBookingToRefund
	} = BookingListLogic();
	
	useEffect(() => {
		refreshData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	const adminCanRefund = myself.rights.role_superadmin == true || myself.rights.role_transaction_refund == true;
	
	if (bookingsList){
		return (
			<>
			{bookingToRefund != null && <Dialog
					open={showDialog}
					onClose={() => setShowDialog(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{`Confirmez le remboursement de ${bookingToRefund?.user_first_name ?? ""} ${bookingToRefund?.user_last_name ?? ""}`}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{console.info(`Please confirm refund action on booking ${bookingToRefund?.uuid}`)}
							{`Êtes-vous certain de vouloir rembourser ${bookingToRefund?.user_first_name ?? ""} ${bookingToRefund?.user_last_name ?? ""} de ${bookingToRefund?.payflow_in?.amount / 100.0 ?? 0}€ ? La confirmation est irréversible.`}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setShowDialog(false)} color="info">Annuler</Button>
						<Button
							onClick={async () => {
								await refundBooking();
								setShowDialog(false);
							}}
							color="danger"
							autoFocus
						>Rembourser</Button>
					</DialogActions>
				</Dialog>}
			<GridItem xs={12} sm={12} md={12}>
				{!myself?.rights?.role_superadmin && !myself?.rights?.role_bookings_read ? (
					<GridItem xs={12} sm={12} md={6}>
						<h4>
							{
								"Vous n'avez pas la permission de voir les réservations"
							}
						</h4>
					</GridItem>
				) : (
					<div>
						<Card
							style={{
								boxShadow: "none",
								marginTop: 0,
								paddingTop: 0,
							}}>
							<CardHeader
								style={{
									borderRadius: 1,
									paddingTop: 0,
									border: "none",
									boxShadow:
										"inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
								}}
							>
								<GridContainer>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<GridItem xs={12} sm={12} md={3}>
											<DatePicker
												autoOk
												label="Date de début"
												labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
												value={startDate}
												onChange={date => {
													date >= endDate && setEndDate(moment(date).add(1, "months"));
													setStartDate(date);
												}}
												InputProps={{ className: classes.input }}
												InputLabelProps={{
													classes: {
														root: textClasses.inputLabelRoot,
													},
												}}
												classes={{ root: textClasses.formControlRoot }}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<DatePicker
												autoOk
												label="Date de fin"
												labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
												value={endDate}
												onChange={date => {
													date <= startDate && setStartDate(moment(date).subtract(1, "months"));
													setEndDate(date);
												}}
												InputProps={{ className: classes.input }}
												InputLabelProps={{
													classes: {
														root: textClasses.inputLabelRoot,
													},
												}}
												classes={{ root: textClasses.formControlRoot }}
											/>
										</GridItem>
									</MuiPickersUtilsProvider>
									<GridItem xs={12} sm={12} md={6}>
										<TextField
											fullWidth
											variant="standard"
											classes={{
												root: textClasses.formControlRoot,
											}}
											label="Rechercher par mot clé"
											onChange={ev => {
												//if (ev.key === "Enter") {
													ev.preventDefault();
													refreshData();
												//}
											}}
											inputProps={{
												// placeholder: "Rechercher par mot clé",
												className: classes.input,
												onChange: event => {
													toggleQuery(event.target.value);
												},
											}}
											InputLabelProps={{
												classes: {
													root: textClasses.inputLabelRoot,
												},
											}}
											value={query}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									{ressources && (<GridItem xs={12} sm={12} md={12}>
										<Autocomplete
											multiple
											id="tags-groups"
											options={ressources}
											getOptionLabel={option => option?.title ?? ''}
											onChange={(_, value) => {
												toggleSelectedRessource(value);
											}}
											value={ressource}
											renderInput={params => (
												<TextField
													{...params}
													variant="standard"
													classes={{
														root: textClasses.formControlRoot,
													}}
													label="Filtrer par ressource"
													InputProps={{
														...params.InputProps,
														className: classes.input,
													}}
													InputLabelProps={{
														classes: {
															root: textClasses.inputLabelRoot,
														},
													}}
												/>
											)}
										/>
									</GridItem>)
									}
									<GridItem xs={12} sm={12} md={6}>
										<Autocomplete
											id="tags-booking-periods"
											options={allPeriods}
											getOptionSelected={(option, value) => {
												return option?.id === value?.id
											}}
											getOptionLabel={option => option?.title ?? ''}
											onChange={(_, value) => {
												updatePeriodFilter(value);
											}}
											value={period}
											renderInput={params => (
												<TextField
													{...params}
													variant="standard"
													classes={{
														root: textClasses.formControlRoot,
													}}
													label="Filtrer par Période"
													InputProps={{
														...params.InputProps,
														className: classes.input,
													}}
													InputLabelProps={{
														classes: {
															root: textClasses.inputLabelRoot,
														},
													}}
												/>
											)}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={6}>
										<Autocomplete
											multiple
											id="tags-booking-status"
											options={statusBookings}
											getOptionSelected={(option, value) => option.id === value.id}
											getOptionLabel={option => option?.title ?? ''}
											onChange={(_, value) => {
												updateBookingStatusFilter(value);
											}}
											value={bookingStatus}
											renderInput={params => (
												<TextField
													{...params}
													variant="standard"
													classes={{
														root: textClasses.formControlRoot,
													}}
													label="Filtrer par statut"
													InputProps={{
														...params.InputProps,
														className: classes.input,
													}}
													InputLabelProps={{
														classes: {
															root: textClasses.inputLabelRoot,
														},
													}}
												/>
											)}
										/>
									</GridItem>
								</GridContainer>
							</CardHeader>
						</Card>
						<CardBody>
							{bookingsList?.data.rows.length > 0 ? (
								<Table
									tableHeaderColor="primary"
									tableHead={[
										"ID",
										"Statut",
										"Ressource",
										"Date",
										"Début",
										"Fin",
										"Période",
										"Prénom",
										"Nom",
										"Email",
										"Télephone",
										"Code",
										"Transaction",
										"Montant Payé",
										"Montant Remboursé",
										'Action'
									].filter((item) => {
										if (item === 'Action' && (!adminCanRefund)){//Remove Refund capabilities if not allowed
											return false;
										}
										return true;
									})}
									tableData={bookingsList?.data.rows.map(booking => {
										const checkBookingRefundable = (booking) => {
											const elevenMonthAgo = moment().subtract(11, 'months');
											const payflowIn = booking?.payflow_in;
											const refoundOut = booking?.payflow_out_refund;
											if (!payflowIn){
												console.info("Booking has no income payflow");
												return false;
											}
											if (parseInt(payflowIn?.amount ?? 0, 10) <= 0){
												console.info("Booking amount is not positive");
												return false;
											}
											if (payflowIn?.status !== "SUCCEEDED"){
												console.info("Booking has not succeeded");
												return false;
											}
											if (refoundOut?.status === "SUCCEEDED"){
												console.info("Booking already refunded");
												return false;
											}
											const payflowInCreatedAt = moment(payflowIn.created_at);
											if (elevenMonthAgo.isBefore(payflowInCreatedAt)){
												return true;
											}
											return false;
										}
										
										const payedAmount = (booking) => {
											let strToReturn = '';
											const payedAmount =  booking?.payflow_in?.status === "SUCCEEDED" ? (booking?.payflow_in?.amount ?? 0) : 0;
											const consumedAmount = booking?.payflow_out?.status === "SUCCEEDED" ? (booking?.payflow_out?.amount ?? 0) : 0;
											
											const creditUsed = consumedAmount - payedAmount;
											let consumedAmountStr = consumedAmount > 0 ? `${consumedAmount / 100.0}€` : '---';
											if (payedAmount > 0 || creditUsed > 0){
												strToReturn = `${payedAmount/100.0}€`;
												if (consumedAmount > 0 && payedAmount != consumedAmount){
													strToReturn = `${strToReturn} (${payedAmount/100.0}€CB + ${(consumedAmount - payedAmount)/100.00}Cr)`
												}
											}else{
												strToReturn = `---`;
											}
											return strToReturn;
										}
										
										const refundAmount = (booking) => {
											let strToReturn = "";
											let refundAmountAsReal = booking?.payflow_out_refund?.status === "SUCCEEDED" ?  (booking?.payflow_out_refund?.amount ?? 0) : 0;
											let refundAmountAsCredit = booking?.payflow_in_refund?.status === "SUCCEEDED" ? (booking?.payflow_in_refund?.amount ?? 0) : 0;
											let refundAmountStr = '';
											if (refundAmountAsReal > 0 || refundAmountAsCredit > 0){
												strToReturn = `${refundAmountAsReal/100.0}€`
												if (refundAmountAsCredit && refundAmountAsReal != refundAmountAsCredit){
													strToReturn = `${strToReturn} (${refundAmountAsReal/100.0}€CB + ${(refundAmountAsCredit - refundAmountAsReal)/100.00}Cr)`
												}
											}else{
												strToReturn = "---";
											}
											return strToReturn;
										}
										
										let hasSomethingToRefund = booking?.payflow_out_refund?.status === "SUCCEEDED" && booking?.payflow_out_refund?.amount > 0;
										
										let period = "";
										const now = moment();
										const beginDate = moment(booking.begin_at);
										const endDate = moment(booking.end_at);
										const bookingStarted = beginDate.isBefore(now);
										const bookingEnded = endDate.isBefore(now);
										if (bookingEnded){
											period = "Passée";
										}else if(bookingStarted){
											period = "En cours";
										}else{
											period = "À venir";
										}
										const transactionStatus = booking?.payflow_out?.status;
										let transactionStatusStr = 'Aucune';
										if (transactionStatus === 'SUCCEEDED'){transactionStatusStr = 'Confirmée'};
										if (transactionStatus === 'CREATED'){transactionStatusStr = 'En cours'};
										if (transactionStatus === 'FAILED'){transactionStatusStr = 'Échec'};
										const bookingStatus = booking?.status;
										let bookingStatusStr = '---';
										if (bookingStatus == 'booked'){bookingStatusStr = "Reservé"};
										if (bookingStatus == 'canceled'){bookingStatusStr = "Annulé"};
										if (bookingStatus == 'initiated'){bookingStatusStr = "Initié"};
										const ressourceUsed = booking?.ressource?.title;
										const ressourceUsedStr = ressourceUsed ? ressourceUsed : "Non indiqué"
										const bookingRefundable = checkBookingRefundable(booking);
										const bookingList = {
											id: booking?.id,
											status: bookingStatusStr,
											ressource: ressourceUsedStr,
											date: moment(booking?.created_at).format("DD/MM/YY HH[:]mm"),
											begin: beginDate.format("DD/MM/YY HH[:]mm"),
											end: moment(booking?.end_at).format("DD/MM/YY HH[:]mm"),
											period: period,
											firstName: booking?.user_first_name,
											lastName: booking?.user_last_name,
											email: booking?.user_email,
											phoneNumber: booking?.sms_number,
											code: booking?.access_code,
											transactionState: transactionStatusStr,
											payedAmount: payedAmount(booking),
											refundAmount: refundAmount(booking),
											action: (<div style={{}}>
													{bookingRefundable && bookingEnded ? <Button
														style={{ marginLeft: 'auto', }}
														size="sm"
														onClick={() => {
															setBookingToRefund(booking);
															setShowDialog(true);
														}}
														color="warning"
													>
														Rembourser
													</Button> : <div></div>
												}
												</div>
											)
										};
										if (!adminCanRefund){//Remove Refund capabilities if not allowed
											delete bookingList['action'];
										}
										return Object.values(bookingList);
									})}
								/>
							) : (
								<h4>Aucune réservation à afficher</h4>
							)}
						</CardBody>
						<CardFooter
							style={{ display: "flex", justifyContent: "center" }}
						>
							{bookingsList?.data.rows.length > 0 && (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										paddingTop: 100,
									}}
								>
									<Pagination
										count={bookingsList?.data.meta.pageCount}
										page={page}
										onChange={(event, pageNumber) => {
											handlePagination(pageNumber);
										}}
										classes={{ ul: paginationClasses.ul }}
										size="large"
									/>
								</div>
							)}
						</CardFooter>
					</div>
				)}
			</GridItem>
			</>
		)
	}else{
		return (
			<div className={classes.circularProgress}>
				<CircularProgress />
			</div>
		);
	}
}