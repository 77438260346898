/* eslint-disable react/no-children-prop */
import React, { useEffect } from "react";

import moment from "moment";
import localization from "moment/locale/fr";

// Other front packages
import Tabs from "components/CustomTabs/CustomTabs.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import PayinsView from "./TabViews/PayinsView";
import PayoutsView from "./TabViews/PayoutsView";
import BankAccountsView from "./TabViews/BankAccountsView";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import RefundsView from "./TabViews/RefundsView";
import UndoIcon from '@material-ui/icons/Undo';
import CreditsView from "./TabViews/CreditsView";
import MoneyIcon from '@material-ui/icons/Money';
import EuroSymbol from '@material-ui/icons/EuroSymbol';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

moment.updateLocale("fr", localization);

export default function Transactions(props) {

	return (
		<div>
			<GridContainer>
				<Tabs
					tabs={[
						{
							tabName: "Paiements",
							tabIcon: CreditCardIcon,
							tabContent: <PayinsView />,
						},
						{
							tabName: "Remboursements",
							tabIcon: UndoIcon,
							tabContent: <RefundsView />,
						},
						{
							tabName: "Crédits",
							tabIcon: MoneyIcon,
							tabContent: <CreditsView />,
						},
						{
							tabName: "Virements",
							tabIcon: EuroSymbol,
							tabContent: <PayoutsView />,
						},
						{
							tabName: "Comptes",
							tabIcon: AccountBalanceIcon,
							tabContent: <BankAccountsView />,
						}
					]}
				/>
			</GridContainer>
		</div>
	);
}