/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import InputColor from "react-input-color";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormEstates(props) {
  const classes = useStyles();

  const {
    newEstateInfo,
    formValid,
    handleFormChange,
    submitNewEstate,
    toggleModal,
  } = props;
  return (
    <Container
      component="main"
      maxWidth="md"
      style={{
        overflowY: "auto",
      }}
    >
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          <Typography variant="h5">Ajouter une résidence</Typography>
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewEstate} noValidate>
          <Grid container spacing={3}>
            <Grid item md={8}>
              <InputLabel>Titre *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newEstateInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleFormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={4}>
            </Grid>
            <Grid item md={3}>
              <InputLabel>Code résidence *</InputLabel>
              <TextField
                id="code"
                value={newEstateInfo.estateCode}
                onChange={e =>
                  handleFormChange("estateCode", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                value="top"
                control={
                  <InputColor
                    initialValue={newEstateInfo.color_main}
                    onChange={color => handleFormChange("color_main", color.hex)}
                    placement="right"
                  />
                }
                label="Couleur principale *"
                labelPlacement="top"
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                value="top"
                control={
                  <InputColor
                    initialValue={newEstateInfo.color_background}
                    onChange={color => handleFormChange("color_background", color.hex)}
                    placement="right"
                  />
                }
                label="Couleur principale *"
                labelPlacement="top"
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Adresse *</InputLabel>
              <TextField
                defaultValue={newEstateInfo.address}
                name="address"
                required
                fullWidth
                id="address"
                onChange={event =>
                  handleFormChange("address", event.target.value)
                }
              />
            </Grid>
            <Grid item md={7}>
              <InputLabel>Ville</InputLabel>
              <TextField
                id="city"
                value={newEstateInfo.city}
                onChange={e =>
                  handleFormChange("city", e.target.value)
                }
                fullWidth
              />
            </Grid>
            <Grid item md={2}>
              <InputLabel>Code Postale</InputLabel>
              <TextField
                id="zicode"
                value={newEstateInfo.zipcode}
                onChange={e =>
                  handleFormChange("zipcode", e.target.value)
                }
                fullWidth
              />
            </Grid>
            <Grid item md={3}>
              <InputLabel>Pays</InputLabel>
              <Select
                value={newEstateInfo.country || "null"}
                required
                fullWidth
                id="country"
                onChange={event =>
                  handleFormChange('country', event.target.value)
                }
                inputProps={{
                  name: "country",
                  id: "country",
                }}
              >
                <MenuItem value={"DE"}>Allemagne</MenuItem>
                <MenuItem value={"AT"}>Autriche</MenuItem>
                <MenuItem value={"BE"}>Belgique</MenuItem>
                <MenuItem value={"BG"}>Bulgarie</MenuItem>
                <MenuItem value={"CY"}>Chypre</MenuItem>
                <MenuItem value={"HR"}>Croatie</MenuItem>
                <MenuItem value={"DK"}>Danemark</MenuItem>
                <MenuItem value={"ES"}>Espagne</MenuItem>
                <MenuItem value={"EE"}>Estonie</MenuItem>
                <MenuItem value={"FI"}>Finland</MenuItem>
                <MenuItem value={"FR"}>France</MenuItem>
                <MenuItem value={"GR"}>Grèce</MenuItem>
                <MenuItem value={"HU"}>Hongrie</MenuItem>
                <MenuItem value={"IE"}>Ireland</MenuItem>
                <MenuItem value={"IT"}>Italie</MenuItem>
                <MenuItem value={"LV"}>Lettonie</MenuItem>
                <MenuItem value={"LT"}>Lituanie</MenuItem>
                <MenuItem value={"LU"}>Luxembourg</MenuItem>
                <MenuItem value={"MT"}>Malte</MenuItem>
                <MenuItem value={"NL"}>Pays-Bas</MenuItem>
                <MenuItem value={"PL"}>Pologne</MenuItem>
                <MenuItem value={"PT"}>Portugal</MenuItem>
                <MenuItem value={"CZ"}>République tchèque</MenuItem>
                <MenuItem value={"RO"}>Roumanie</MenuItem>
                <MenuItem value={"GB"}>Royaume-Uni</MenuItem>
                <MenuItem value={"SK"}>Slovaquie</MenuItem>
                <MenuItem value={"SI"}>Slovénie</MenuItem>
                <MenuItem value={"SE"}>Suède</MenuItem>
              </Select>
            </Grid>
            <Grid item md={3}>
              <InputLabel>Fuseau horaire *</InputLabel>
              <Select
                value={newEstateInfo.timezone || "null"}
                required
                fullWidth
                id="timezone"
                onChange={event =>
                  handleFormChange('timezone', event.target.value)
                }
              >
                <MenuItem value={"Europe/Amsterdam"}>Europe/Amsterdam</MenuItem>
                <MenuItem value={"Europe/Andorra"}>Europe/Andorra</MenuItem>
                <MenuItem value={"Europe/Astrakhan"}>Europe/Astrakhan</MenuItem>
                <MenuItem value={"Europe/Athens"}>Europe/Athens</MenuItem>
                <MenuItem value={"Europe/Belgrade"}>Europe/Belgrade</MenuItem>
                <MenuItem value={"Europe/Berlin"}>Europe/Berlin</MenuItem>
                <MenuItem value={"Europe/Bratislava"}>Europe/Bratislava</MenuItem>
                <MenuItem value={"Europe/Brussels"}>Europe/Brussels</MenuItem>
                <MenuItem value={"Europe/Budapest"}>Europe/Budapest</MenuItem>
                <MenuItem value={"Europe/Copenhagen"}>Europe/Copenhagen</MenuItem>
                <MenuItem value={"Europe/Chisinau"}>Europe/Chisinau</MenuItem>
                <MenuItem value={"Europe/Dublin"}>Europe/Dublin</MenuItem>
                <MenuItem value={"Europe/Gibraltar"}>Europe/Gibraltar</MenuItem>
                <MenuItem value={"Europe/Guernsey"}>Europe/Guernsey</MenuItem>
                <MenuItem value={"Europe/Helsinki"}>Europe/Helsinki</MenuItem>
                <MenuItem value={"Europe/Isle_of_Man"}>Europe/Isle_of_Man</MenuItem>
                <MenuItem value={"Europe/Istanbul"}>Europe/Istanbul</MenuItem>
                <MenuItem value={"Europe/Jersey"}>Europe/Jersey</MenuItem>
                <MenuItem value={"Europe/Kaliningrad"}>Europe/Kaliningrad</MenuItem>
                <MenuItem value={"Europe/Kyiv"}>Europe/Kyiv</MenuItem>
                <MenuItem value={"Europe/Kirov"}>Europe/Kirov</MenuItem>
                <MenuItem value={"Europe/Lisbon"}>Europe/Lisbon</MenuItem>
                <MenuItem value={"Europe/London"}>Europe/London</MenuItem>
                <MenuItem value={"Europe/Ljubljana"}>Europe/Ljubljana</MenuItem>
                <MenuItem value={"Europe/Luxembourg"}>Europe/Luxembourg</MenuItem>
                <MenuItem value={"Europe/Madrid"}>Europe/Madrid</MenuItem>
                <MenuItem value={"Europe/Malta"}>Europe/Malta</MenuItem>
                <MenuItem value={"Europe/Monaco"}>Europe/Monaco</MenuItem>
                <MenuItem value={"Europe/Mariehamn"}>Europe/Mariehamn</MenuItem>
                <MenuItem value={"Europe/Minsk"}>Europe/Minsk</MenuItem>
                <MenuItem value={"Europe/Moscow"}>Europe/Moscow</MenuItem>
                <MenuItem value={"Europe/Oslo"}>Europe/Oslo</MenuItem>
                <MenuItem value={"Europe/Paris"}>Europe/Paris</MenuItem>
                <MenuItem value={"Europe/Podgorica"}>Europe/Podgorica</MenuItem>
                <MenuItem value={"Europe/Prague"}>Europe/Prague</MenuItem>
                <MenuItem value={"Europe/Rome"}>Europe/Rome</MenuItem>
                <MenuItem value={"Europe/Riga"}>Europe/Riga</MenuItem>
                <MenuItem value={"Europe/Simferopol"}>Europe/Simferopol</MenuItem>
                <MenuItem value={"Europe/Samara"}>Europe/Samara</MenuItem>
                <MenuItem value={"Europe/Saratov"}>Europe/Saratov</MenuItem>
                <MenuItem value={"Europe/San_Marino"}>Europe/San_Marino</MenuItem>
                <MenuItem value={"Europe/Sarajevo"}>Europe/Sarajevo</MenuItem>
                <MenuItem value={"Europe/Skopje"}>Europe/Skopje</MenuItem>
                <MenuItem value={"Europe/Stockholm"}>Europe/Stockholm</MenuItem>
                <MenuItem value={"Europe/Sofia"}>Europe/Sofia</MenuItem>
                <MenuItem value={"Europe/Tallinn"}>Europe/Tallinn</MenuItem>
                <MenuItem value={"Europe/Tirane"}>Europe/Tirane</MenuItem>
                <MenuItem value={"Europe/Ulyanovsk"}>Europe/Ulyanovsk</MenuItem>
                <MenuItem value={"Europe/Vilnius"}>Europe/Vilnius</MenuItem>
                <MenuItem value={"Europe/Volgograd"}>Europe/Volgograd</MenuItem>
                <MenuItem value={"Europe/Vaduz"}>Europe/Vaduz</MenuItem>
                <MenuItem value={"Europe/Vatican"}>Europe/Vatican</MenuItem>
                <MenuItem value={"Europe/Vienna"}>Europe/Vienna</MenuItem>
                <MenuItem value={"Europe/Warsaw"}>Europe/Warsaw</MenuItem>
                <MenuItem value={"Europe/Zagreb"}>Europe/Zagreb</MenuItem>
                <MenuItem value={"Europe/Zurich"}>Europe/Zurich</MenuItem>
              </Select>
            </Grid>
            <Grid item md={3}>
              <InputLabel>Devise *</InputLabel>
              <Select
                value={newEstateInfo.currency || "EUR"}
                required
                fullWidth
                id="currency"
                onChange={event =>
                  handleFormChange('currency', event.target.value)
                }
              >
                <MenuItem value={"EUR"}>Euro - €</MenuItem>
              </Select>
            </Grid>
            <Grid item md={3}>
              <InputLabel>Recharge crédit</InputLabel>
              <Switch
                checked={newEstateInfo.credit_wallet_enable}
                onChange={e =>
                  handleFormChange('credit_wallet_enable', e.target.checked)
                }
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
            <Grid item md={3}>
              <InputLabel>Maintenance</InputLabel>
              <Select
                value={newEstateInfo.maintenance_mode || "null"}
                onChange={event =>
                  handleFormChange('maintenance_mode', event.target.value)
                }
                inputProps={{
                  name: "maintenance",
                  id: "maintenance",
                }}
              >
                <MenuItem value={"null"}>
                  <em>Aucune</em>
                </MenuItem>
                <MenuItem value={"TECHNICAL"}>Technique</MenuItem>
                <MenuItem value={"MIGRATION"}>Migration</MenuItem>
              </Select>
            </Grid>
            <Grid item md={12}>
              <InputLabel>Nom de domaine *</InputLabel>
              <TextField
                id="domain"
                value={newEstateInfo.web_host_name}
                onChange={e =>
                  handleFormChange("web_host_name", e.target.value)
                }
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formValid}
          >
            Enregister
          </Button>
        </form>
      </div>
    </Container>
  );
}

FormEstates.propTypes = {
  newEstateInfo: PropTypes.object,
  formValid: PropTypes.bool,
  handleFormChange: PropTypes.func,
  submitNewEstate: PropTypes.func,
  toggleModal: PropTypes.func,
};
