import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  linkPointServices,
  moduleServices,
  featureServices,
  linkGroupServices,
} from "services";

const LinksLogic = () => {

  const { myself } = useSelector(userState => userState.myself);

  const limitLinks = 15

  const [refresh, setRefresh] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [page, setPage] = useState(1)
  const [linksList, setLinksList] = useState(undefined)
  const [linksCount, setLinksCount] = useState(undefined)

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newLinksInfo, setNewLinksInfo] = useState(undefined);
  const [modalType, setModalType] = useState(false);
  const [formGroupsList, setFormGroupsList] = useState(undefined)
  const [formModulesList, setFormModulesList] = useState(undefined)
  const [formValid, setFormValid] = useState(false);

  const [linkDestroy, setLinkDestroy] = useState(undefined);
  const [refreshImage, setRefreshImage] = useState(undefined);

  const toggleConfirmLinkDestroy = link => setLinkDestroy(link);

  useEffect(() => {
    (async () => {
      const links = await linkPointServices.getAll()
      if (links) {
        setLinksCount(links.length)
        const l = links.slice(limitLinks * (page - 1), limitLinks * (page - 1) + limitLinks)
        setLinksList(l)
        if (!refreshImage) {
          let r = {}
          l.forEach(it => r[it.uuid] = false)
          setRefreshImage(r)
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, page]);

  const refreshData = () => setRefresh(!refresh);

  const handlePagination = page => {
    setPage(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const deleteLink = async uuid => {
    await linkPointServices.destroy(uuid)
    openNotification(`Le lien ${linksList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
    toggleConfirmLinkDestroy(null)
    refreshData()
  };

  const refreshImg = uuid => {
    const refreshOn = { ...refreshImage }
    refreshOn[uuid] = true
    setRefreshImage(refreshOn);
    setTimeout(() => {
      const refreshOff = { ...refreshImage }
      refreshOff[uuid] = false
      setRefreshImage(refreshOff)
    }, 700);
  };

  const getAllRessourceform = async () => {
    const group = await linkGroupServices.getAll()
    if (group) {
      setFormGroupsList(group)
    }
    const module = await moduleServices.getAll();
    const feature = await featureServices.getAll()
    if (module && feature) {
      const links = module?.find(it => it.identifier === "links")?.id;
      if (links){
        setFormModulesList(feature.filter(it => it.module_id === links));
      }
    }
  }

  const toggleModal = async (link = null) => {
    getAllRessourceform()
    setFormValid(false);
    setModalType(link ? true : false);
    const linkInfo = {
      uuid: link?.uuid,
      title: link?.title ? link?.title : '',
      link: link?.link ? link?.link : '',
      address: link?.address ? link?.address : '',
      latitude: link?.latitude ? link?.latitude : '',
      longitude: link?.longitude ? link?.longitude : '',
      img: null,
      group: link?.group?.uuid ? link?.group?.uuid : '',
      features: link?.features ? link?.features.map(it => it.uuid) : [],
      has_image: link?.has_image ? link?.has_image : '',
    };
    setNewLinksInfo(linkInfo);
    setEditModalOpened(!editModalOpened);
  };

  const isFormValid = linkInfo => {
    if (!linkInfo?.title?.length || !linkInfo?.link?.length) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  };

  const handleFormChange = (key, value) => {
    let linkInfo = { ...newLinksInfo };
    linkInfo[key] = value;
    setNewLinksInfo(linkInfo);
    isFormValid(linkInfo);
  };

  const deleteIcon = async () => {
    let linkInfo = { ...newLinksInfo };
    linkInfo.has_image = false
    setNewLinksInfo(linkInfo);
    let linkList = linksList.slice(0);
    linkList.find(link => link.uuid === linkInfo.uuid).has_image = false
    setLinksList(linkList.slice(limitLinks * (page - 1), limitLinks * (page - 1) + limitLinks))
    await linkPointServices.unsetImage(linkInfo.uuid)
    refreshImg(linkInfo.uuid)
    refreshData()
    isFormValid(linkInfo);
  }

  const submitNewLink = async event => {
    event.preventDefault();
    setFormValid(false);
    const linkInfo = {
      title: newLinksInfo.title,
      link: newLinksInfo.link,
      address: newLinksInfo.address,
      latitude: newLinksInfo.latitude,
      longitude: newLinksInfo.longitude,
    };
    if (newLinksInfo.uuid) {
      const link = linksList.find(it => it.uuid === newLinksInfo.uuid)
      const features = link.features
      features.filter(feature => !newLinksInfo.features
        .find(it => it === feature.uuid))
        .forEach(async it => await linkPointServices.detachFeature(newLinksInfo.uuid, it.uuid))
      newLinksInfo.features.filter(it => !features
        .find(feature => feature.uuid === it))
        .forEach(async it => await linkPointServices.attachFeature(newLinksInfo.uuid, it))
      if (link.group && newLinksInfo.group !== link.group.uuid) {
        await linkPointServices.detachGroup(newLinksInfo.uuid)
      }
      if (newLinksInfo?.group?.length && link?.group?.uuid !== newLinksInfo.group) {
        await linkPointServices.attachGroup(newLinksInfo.uuid, newLinksInfo.group)
      }
      if (newLinksInfo?.img) {
        let data = new FormData();
        data.append("file", newLinksInfo.img);
        await linkPointServices.setImage(newLinksInfo.uuid, data)
        refreshImg(newLinksInfo.uuid)
      }
      if (await linkPointServices.update(newLinksInfo.uuid, linkInfo)) {
        openNotification(`Le lien ${linkInfo?.title} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification du lien ${linkInfo?.title}`, 'danger')
      }
    } else {
      const link = await linkPointServices.add(linkInfo);
      if (link) {
        for (let x of newLinksInfo.features) {
          await linkPointServices.attachFeature(link.uuid, x)
        }
        openNotification(`Le lien ${link?.title} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout du lien ${link?.title}`, 'danger')
      }
      if (link && newLinksInfo?.group?.length) {
        await linkPointServices.attachGroup(link.uuid, newLinksInfo.group)
      }
      if (link && newLinksInfo?.img) {
        let data = new FormData();
        data.append("file", newLinksInfo.img);
        await linkPointServices.setImage(link.uuid, data)
        refreshImg(link.uuid)
      }
    }
    setEditModalOpened(!editModalOpened);
    setFormValid(true);
    refreshData();
  };

  return {
    editModalOpened,
    formGroupsList,
    formModulesList,
    formValid,
    newLinksInfo,
    notifColor,
    notifMessage,
    modalType,
    myself,
    openNotif,
    linkDestroy,
    linksCount,
    linksList,
    limitLinks,
    page,
    refreshImage,
    deleteIcon,
    deleteLink,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewLink,
    toggleConfirmLinkDestroy,
    toggleModal,
  }
}

export default LinksLogic