import { useEffect, useState, useCallback } from "react";

import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

import {
	sectorServices
} from "services";

const BuildingImportLogic = () => {

	const limitAttributes = 20

	const [refresh, setRefresh] = useState(false);

	const [openNotif, setOpenNotif] = useState(false);
	const [notifMessage, setNotifMessage] = useState("");
	const [notifColor, setNotifColor] = useState("info");

	const [page, setPage] = useState(1)
	const [attributesList, setAttributesList] = useState(undefined)
	const [attributesCount, setAttributesCount] = useState(undefined)

	const [editModalOpened, setEditModalOpened] = useState(false);
	const [newAttributesInfo, setNewAttributesInfo] = useState(undefined);
	const [modalType, setModalType] = useState(false);

	const [errorUpload, setErrorUpload] = useState([]);
	const [uploadAttributeStart, setUploadAttributeStart] = useState(false);
	const [fileUpload, setFileUpload] = useState(false);
	const [file, setFile] = useState(undefined);
	const [fileData, setFileData] = useState([]);
	const [errorFileData, setErrorFileData] = useState(false);
	const [progress, setProgress] = useState(0)
	const [fileTemplate] = useState([
		{
			key: "title",
			title: "Nom du bâtiment",
			include: ["batiment"],
			exclude: [],
			allowNull: false,
		},
	]);

	const [attributeDestroy, setAttributeDestroy] = useState(undefined);

	const toggleConfirmAttributeDestroy = attribute => setAttributeDestroy(attribute);

	useEffect(() => {
		(async () => {
			const attribute = await sectorServices.getAll();
			if (attribute) {
				setAttributesCount(attribute.length)
				setAttributesList(attribute.slice(limitAttributes * (page - 1), limitAttributes * (page - 1) + limitAttributes))
			}
		})();
	}, [refresh, page]);

	const refreshData = () => setRefresh(!refresh);

	const handlePagination = page => {
		setPage(page);
	};

	const openNotification = (msg, color = "info", time = 6) => {
		setOpenNotif(false);
		setNotifMessage(msg);
		setNotifColor(color);
		setTimeout(() => setOpenNotif(false), time * 1000);
		setTimeout(() => setOpenNotif(true), 100);
	};

	const deleteAttribute = async uuid => {
		await sectorServices.destroy(uuid)
		openNotification(`Le bâtiment ${attributesList.find(it => it.uuid === uuid)?.name} a été supprimé`, 'success')
		toggleConfirmAttributeDestroy(null)
		refreshData()
	};

	const toggleModal = async (attribute = null) => {
		setModalType(attribute ? true : false);
		const attributeInfo = {
			uuid: attribute?.uuid,
			title: attribute?.name ? attribute?.name : '',
			streetNumber: attribute?.streetNumber ? attribute?.streetNumber : '',
			streetName: attribute?.streetName ? attribute?.streetName : '',
			isPrivate: attribute?.isPrivate === true ? true : false,
		};
		setNewAttributesInfo(attributeInfo);
		setEditModalOpened(!editModalOpened);
	};

	const handleFormChange = (key, value) => {
		let attributeInfo = { ...newAttributesInfo };
		attributeInfo[key] = value;
		setNewAttributesInfo(attributeInfo);
	};

	const uploadAttribute = async () => {
		setProgress(0)
		setErrorUpload([]);
		setUploadAttributeStart(true);
		let errorTotal = 0;
		let attributeUpload = 0;
		let titles = []
		for (let line of fileData) {
			setProgress((fileData.length / line.line) * 100)
			let error = 0;
			if (line.data?.title !== null && (attributesList.find(it => it.name === line.data.title) ||
				titles.find(it => it === line.data.title))) {
				error += 1;
				setErrorUpload(oldArray => [
					...oldArray,
					`Ligne ${line.line}: ${fileTemplate.find(it => it.key === "title").title
					}: Ce nom existe déjà`,
				]);
			}
			if (error) {
				errorTotal += error;
				continue;
			}
			titles.push(line.data?.title)
			const sector = await sectorServices.add(line.data?.title, "", "", false);
			if (!sector) {
				errorTotal += 1;
				setErrorUpload(oldArray => [
					...oldArray,
					`Ligne ${line.line}: Une erreur est survenu pendant la création de l'utilisateur, vérifier les données ou ressayer ultérieurement`,
				]);
				continue;
			}
			attributeUpload += 1;
		}
		setProgress(100)
		setRefresh(!refresh);
		const attributePlural = attributeUpload > 1 ? "s" : "";
		const errorPlural = errorTotal > 1 ? "s" : "";
		openNotification(
			`Enregistrement terminé: ${attributeUpload} ligne${attributePlural} créé${attributePlural}, ${errorTotal} erreur${errorPlural} détectée${errorPlural}`,
			"info",
			15
		);
		setUploadAttributeStart(false);
	};

	const generateHeaderInfo = (line = 1) => {
		let header = { line };
		fileTemplate.map(it => (header[it.key] = null));
		return header;
	};

	const validateHeaderInfo = headerInfo => {
		return fileTemplate.find(it => headerInfo[it.key] === null) === undefined;
	};

	const parseFileKey = (cell, headerInfo, col) => {
		for (let box of fileTemplate) {
			if (
				!headerInfo[box.key] &&
				box.include
					.map(it => cell.toLowerCase().search(it) < 0)
					.reduce((a, b) => a + b, 0) === 0 &&
				box.exclude
					.map(it => cell.toLowerCase().search(it) >= 0)
					.reduce((a, b) => a + b, 0) === 0
			) {
				headerInfo[box.key] = col;
				return headerInfo;
			}
		}
		return headerInfo;
	};

	const fillFileData = (v, data, line, key) => {
		if (!data.find(it => it.line === line)) {
			let l = { line, data: {} };
			fileTemplate.map(it => (l.data[it.key] = null));
			data.push(l);
		}
		data.find(it => it.line === line).data[key] = v.toString();
		return data;
	};

	const parseFileData = (f, headerInfo) => {
		let data = [];
		const keys = Object.keys(f).filter(it => it[0] !== "!");
		const keysHeader = fileTemplate.map(it => it.key);
		keys.forEach(c => {
			const line = c.match(/^\d+|\d+\b|\d+(?=\w)/g)[0];
			const col = c.replace(/[0-9]/g, "");
			if (parseInt(line) <= parseInt(headerInfo.line)) return;
			const key = keysHeader.find(it => headerInfo[it] === col);
			if (key) {
				data = fillFileData(f[c].v, data, line, key);
			}
		});
		setFileData(data);
		setFileUpload(true);
	};

	const parseFile = f => {
		let headerInfo = generateHeaderInfo();
		let line = 1;
		let col = "A";
		const keys = Object.keys(f).filter(it => it[0] !== "!");
		for (let c of keys) {
			line = c.match(/^\d+|\d+\b|\d+(?=\w)/g)[0];
			col = c.replace(/[0-9]/g, "");
			if (line !== headerInfo.line) headerInfo = generateHeaderInfo(line);
			headerInfo = parseFileKey(
				f[c].v
					.toString()
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, ""),
				headerInfo,
				col
			);
			if (validateHeaderInfo(headerInfo)) {
				parseFileData(f, headerInfo);
				return;
			}
		}
		openNotification(
			"Erreur lors de la lecture du fichier. Vérifiez que le titre des colonnes comprend ces champs: " +
			fileTemplate.map(it => it.title).join(", "),
			"danger",
			15
		);
	};

	const readFile = () => {
		setErrorUpload([]);
		setUploadAttributeStart(false);
		setFileUpload(false);
		setErrorFileData(false);
		const reader = new FileReader();
		reader.onabort = () => console.log("file reading was aborted");
		reader.onerror = () => {
			console.error("file reading has failed");
			openNotification(
				"La lecture du fichier a échoué ! Vérifiez que le fichier existe ou ressayer de le sélectionner",
				"danger",
				15
			);
		};
		reader.onload = evt => {
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: "binary" });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			parseFile(ws);
		};
		reader.readAsBinaryString(file);
	};

	const onDrop = useCallback(acceptedFiles => {
		if (acceptedFiles.length === 1) setFile(acceptedFiles[0]);
	}, []);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		type: "file",
		accept:
			"application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet, text/csv",
		onDrop,
		maxFiles: 1,
	});

	return {
		attributeDestroy,
		attributesCount,
		attributesList,
		editModalOpened,
		errorFileData,
		errorUpload,
		file,
		fileData,
		fileTemplate,
		fileUpload,
		isDragActive,
		isDragAccept,
		isDragReject,
		limitAttributes,
		modalType,
		newAttributesInfo,
		notifColor,
		notifMessage,
		openNotif,
		page,
		progress,
		uploadAttributeStart,
		deleteAttribute,
		getRootProps,
		getInputProps,
		handleFormChange,
		handlePagination,
		readFile,
		refreshData,
		setErrorFileData,
		setOpenNotif,
		toggleConfirmAttributeDestroy,
		toggleModal,
		uploadAttribute,
	}
}

export default BuildingImportLogic