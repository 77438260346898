import { useEffect, useState } from "react";
import { transactionServices } from "services";

const PayoutsLogic = () => {

	const [refresh, setRefresh] = useState(false);

	const [page, setPage] = useState(1)
	const [itemsList, setItemsList] = useState(undefined);
	const [itemsCount, setItemsCount] = useState(undefined);
	const [limit, setLimit] = useState(10)

	useEffect(() => {
		(async () => {
			const responseData = await transactionServices.getPayouts(page);
			if (responseData) {
				setItemsCount(responseData.count)
				setItemsList(responseData.rows)
				setLimit(responseData.limit)
			}
		})();
	}, [refresh, page]);

	const refreshData = () => setRefresh(!refresh);
	
	const handlePagination = page => {
		setPage(page);
	};

	return {
		itemsCount,
		itemsList,
		limit,
		page,
		handlePagination,
		refreshData
	}
}

export default PayoutsLogic