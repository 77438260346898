import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const groupServices = {
  add,
  getAllByEstate,
  getAllBySector,
  attachUser,
  detachUser,
  destroy,
  setDefaultGroupForCurrentUser,
  update,
  updateCollection,
};

async function setDefaultGroupForCurrentUser(groupUUID) {
  return put_axs(`/groups/self/default/${groupUUID}`);
}

async function getAllByEstate() {
  return get_axs("/groups");
}

async function getAllBySector(id) {
  return get_axs(`/groups/${id}`);
}

async function attachUser(userUID, groupUUID, csrfToken) {
  return post_axs(
    "/groups/attach/user",
    new URLSearchParams({
      user_uuid: userUID,
      group_uuid: groupUUID,
      token: csrfToken.token,
      user_uuid_crsf: csrfToken.user_uuid,
    })
  );
}

async function detachUser(userUID, groupUUID) {
  return remove_axs(`/groups/${groupUUID}/detach/user/${userUID}`);
}

async function add(title, sectorUUID, isPrivate) {
  return post_axs(
    `/groups`,
    new URLSearchParams({
      title,
      sectorUUID,
      isPrivate
    })
  );
}

async function update(uuid, title, sectorUUID, isPrivate) {
  return put_axs(
    `/groups/${uuid}`,
    new URLSearchParams({
      title,
      sectorUUID,
      isPrivate
    })
  );
}

async function updateCollection(groupUUID, collectionUUID) {
  return put_axs(`/groups/${groupUUID}/collection/${collectionUUID}`);
}

async function destroy(groupUUID) {
  return remove_axs(`/groups/${groupUUID}`);
}
