import React, { useEffect } from "react";
import moment from "moment";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CreditsLogic from "../TabLogics/CreditsLogic";
//import LinkIcon from '@material-ui/icons/Link';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
	Link
} from "@material-ui/core";

import {makeStyles} from "@material-ui/core";


export default function CreditsView(props) {
	
	const {
		items,
		refreshData
	} = CreditsLogic();
	
	useEffect(() => {
		refreshData()
	}, [props.refresh]);

	let rowNb = 1;
	
	return (
		<>
			<GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{items && items.length === 0 ? (
						<h4>Aucune crédits utilisateurs</h4>
					) :
						(<Table
							tableHeaderColor="primary"
							tableType="transactions"
							tableHead={
								[
									"",
									"Nom",
									"Montant",
									"Remboursable"
								]
							}
							tableData={
								items &&
								items.map(item => {
									const amount = ((parseInt(item.credits, 10) ?? 0) / 100).toFixed(2);
									const refundable = ((parseInt(item.refundable, 10) ?? 0) / 100).toFixed(2);
									let civility = item?.civility ?? "";
									civility = (civility === "MR" ? "M." : civility === "ML" ? "Mme." : "Mme.")
									let firstName = item?.firstName ?? "";
									let lastName = item?.lastName ?? "";
									const itemList = {
									  rowId: `${rowNb}`,
										author: `${civility} ${firstName} ${lastName}`,
										amount: `${amount}€`,
										refundable: `${refundable}€`
									};
									rowNb += 1;
									return Object.values(itemList);
								})
							}
						/>)
					}
				</GridItem>
			</GridItem>
		</>
	)
}