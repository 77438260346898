import { get_axs, post_axs, remove_axs, put_axs } from "helpers/fetcher";

export const transactionServices = {
	getPayins,
	getRefunds,
	getCredits,
	getPayouts,
	getIBANBankAccounts
};

async function getPayouts(page = 1) {
	page = parseInt(page, 10) ?? 1;
	return get_axs(`/transaction/payouts?page=${page}`);
}

async function getPayins(page = 1) {
	page = parseInt(page, 10) ?? 1;
	return get_axs(`/transaction/payins?page=${page}`);
}

async function getRefunds(page = 1) {
	page = parseInt(page, 10) ?? 1;
	return get_axs(`/transaction/refunds?page=${page}`);
}

async function getCredits() {
	return get_axs(`/transaction/credits`);
}

async function getIBANBankAccounts() {
	return get_axs(`/transaction/bankAccounts/IBAN`);
}