import React, { useEffect } from "react";
import moment from "moment";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import BankAccountsLogic from "../TabLogics/BankAccountsLogic";
//import LinkIcon from '@material-ui/icons/Link';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
	Link
} from "@material-ui/core";

import {makeStyles} from "@material-ui/core";

const listDateFormat = "DD/MM/YYYY";

export default function BankAccountsView(props) {
	
	const {
		items,
		refreshData
	} = BankAccountsLogic();
	
	useEffect(() => {
		refreshData()
	}, [props.refresh]);

	let rowNb = 1;
	
	return (
		<>
			<GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{items && items.length === 0 ? (
						<h4>Aucun compte bancaire</h4>
					) :
						(<Table
							tableHeaderColor="primary"
							tableType="transactions"
							tableHead={
								[
									"",
									"Nom",
									"IBAN",
									"BIC",
									"Actif",
									"Créé le"
								]
							}
							tableData={
								items &&
								items.map(item => {
									console.log(item);
									const itemList = {
										rowId: `${rowNb}`,
										ownerName: `${item?.ownerName}`,
										iban: `${item?.iban}`,
										bic: `${item?.bic}`,
										active: `${item?.active ? "OUI" : "NON"}`,
										creationDate: moment(item?.creationDate).format(listDateFormat),
									};
									rowNb += 1;
									return Object.values(itemList);
								})
							}
						/>)
					}
				</GridItem>
			</GridItem>
		</>
	)
}