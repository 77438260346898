import { get_axs, post_axs, put_axs, remove_axs } from "helpers/fetcher";

export const sectorServices = {
  getAll,
  add,
  destroy,
  update,
  attachUser,
  detachUser,
};

async function getAll() {
  const sectors = get_axs("/sectors");
  return sectors;
}

async function add(title, streetNumber, streetName, isPrivate) {
  if (typeof streetNumber === 'undefined'){
    streetNumber = '';
  }
  if (typeof streetName === 'undefined'){
    streetName = '';
  }
  const response = post_axs(
    "/sectors",
    new URLSearchParams({
      title,
      streetNumber,
      streetName,
      isPrivate
    })
  );
  return response
}
async function destroy(uuid) {
  return remove_axs(`/sectors/${uuid}`);
}

async function update(uuid, title, streetNumber, streetName, isPrivate) {
  return put_axs(
    `/sectors/${uuid}`,
    new URLSearchParams({
      title,
      streetNumber,
      streetName,
      isPrivate
    })
  );
}

async function attachUser(userUID, sectorUUID, csrfToken) {
  return post_axs(
    "/sectors/attach/user",
    new URLSearchParams({
      user_uid: userUID,
      sector_uuid: sectorUUID,
      token: csrfToken.token,
      user_uuid_crsf: csrfToken.user_uuid,
    })
  );
}

async function detachUser(userUID, sectorUUID) {
  return remove_axs(
    "/sectors/detach/user",
    new URLSearchParams({ userUID, sectorUUID })
  );
}
