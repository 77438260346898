/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { moduleServices } from "services";
// @material-ui/icons
import {
  AddAlert,
  Apartment,
  Description,
  DirectionsBike,
  Equalizer,
  Event,
  Group,
  Language,
  Link,
  Mail,
  MenuBook,
  Message,
  Person,
  ReportProblem,
  RssFeed,
  Settings,
  ShoppingCart,
  SupervisorAccount,
  ViewColumn,
  ViewModule,
  EuroSymbol,
} from "@material-ui/icons";
import { OubaboxIcon } from "./assets/img/icons";

import Events from "./views/Oubaccess/Modules/Events/Events";
import Files from "./views/Oubaccess/Modules/Files/Files";
import NewsSettings from "./views/Oubaccess/Modules/News/Settings/NewsSettings";
import News from "./views/Oubaccess/Modules/News/News";
import Bookings from "./views/Oubaccess/Modules/Bookings/Bookings";
import BookingsSettings from "./views/Oubaccess/Modules/Bookings/BookingsSettings";
import Links from "./views/Oubaccess/Modules/Links/Links";
import LinksSettings from "./views/Oubaccess/Modules/Links/Settings/LinksSettings";
import Classified from "./views/Oubaccess/Modules/Classified/Classified";
import ClassifiedSettings from "./views/Oubaccess/Modules/Classified/Settings/ClassifiedSettings";
import Incidents from "./views/Oubaccess/Modules/Incidents/Incidents";
import IncidentsSettings from "./views/Oubaccess/Modules/Incidents/Settings/IncidentsSettings";
import Users from "./views/Common/Users/Users";
import Platform from "./views/Common/Platform/Platform";
import Mails from "./views/Oubaccess/Mails/Mails";
import Transactions from "./views/Oubaccess/Transactions/Transactions";
import AdminPanel from "./views/AdminPanel/AdminPanel";
import Alerts from "./views/Oubaccess/Alerts/Alerts";
import Attributes from "./views/Common/Attributes/Attributes";
import Features from "./views/Common/Features/Features";
import Groups from "./views/Common/Groups/Groups";
import Partners from "./views/Oubaccess/Partners/Partners";
import B2ebikes from "./views/Oubaccess/B2ebikes/B2ebikes";
import Collections from "./views/Common/Collections/Collections";
import Statistics from "./views/Common/Statistics/Statistics";
import OubaboxModule from "./views/Oubabox/Module";

const RoutesParser = () => {
  const [module, setModule] = useState(undefined);
  const [siteModule, setSiteModule] = useState(undefined);
  const { myself, loadedMyself } = useSelector(userState => userState.myself);

  useEffect(() => {
    (async () => {
      if (loadedMyself) {
        const mod = await moduleServices.getAll();
        const modActive = await moduleServices.getAllActives();
        mod && setModule(mod);
        modActive && setSiteModule(modActive);
      }
    })();
  }, [loadedMyself]);

  const dashboardRoutes = [{
    path: "/panel",
    layout: "/admin",
    name: "Panel admin",
    icon: SupervisorAccount,
    component: AdminPanel,
    componentSettings: null,
    settings: null,
    id: -1,
    role: "edit_privilege",
  }, {
    path: "/platform",
    settings: null,
    name: "✨ Paramètre du site",
    icon: Settings,
    component: Platform,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "superadmin",
    menuName: "Configurer",
  }, {
    path: "/features",
    settings: null,
    name: "✨ Modules",
    icon: ViewModule,
    component: Features,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "superadmin",
  }, {
    path: "/partners",
    settings: null,
    name: "✨ Partenaires",
    icon: Message,
    component: Partners,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "superadmin",
  }, {
    path: "/collections",
    settings: null,
    name: "✨ Collections",
    icon: ViewColumn,
    component: Collections,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "superadmin",
  }, {
    path: "/b2ebikes",
    settings: null,
    name: "✨ B2ebike",
    icon: DirectionsBike,
    component: B2ebikes,
    componentSettings: null,
    layout: "/admin",
    id: module?.find(it => it.identifier === "b2ebike").id,
    role: "superadmin",
  }, {
    path: "/statistics",
    name: "Statistiques",
    settings: null,
    icon: Equalizer,
    component: Statistics,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "statistics",
  }, {
    path: "/users",
    settings: null,
    name: "Gestion des utilisateurs",
    icon: Person,
    component: Users,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "users",
    menuName: "Administrer",
  }, {
    path: "/attributes",
    settings: null,
    name: "Secteur / Bâtiments",
    icon: Apartment,
    component: Attributes,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "sectors",
  }, {
    path: "/groups",
    settings: null,
    name: "Groupes / Logement",
    icon: Group,
    component: Groups,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "groups",
  }, {
    path: "/transactions",
    settings: null,
    name: "Transactions",
    icon: EuroSymbol,
    component: Transactions,
    componentSettings: null,
    layout: "/admin",
    role: "transactions",
    id: -1,
    menuName: "Administrer",
  }, {
    path: "/oubabox",
    settings: null,
    name: "Oubabox",
    icon: OubaboxIcon,
    component: OubaboxModule,
    componentSettings: null,
    layout: "/admin",
    id: module?.find(it => it.identifier === "oubabox").id,
    role: "oubabox",
  }, {
    path: "/bookings",
    settings: "/settings/bookings",
    name: "Réservations",
    icon: MenuBook,
    component: Bookings,
    componentSettings: BookingsSettings,
    layout: "/admin",
    id: module?.find(it => it.identifier === "share").id,
    role: "bookings",
    menuName: "Administrer",
  }, {
    path: "/news",
    settings: "/settings/news",
    name: "Actualités",
    icon: Language,
    component: News,
    componentSettings: NewsSettings,
    layout: "/admin",
    id: module?.find(it => it.identifier === "news").id,
    role: "news",
    menuName: "Gérer",
  }, {
    path: "/events",
    settings: null,
    name: "Evénements",
    icon: Event,
    component: Events,
    componentSettings: null,
    layout: "/admin",
    id: module?.find(it => it.identifier === "calendar").id,
    role: "events",
  }, {
    path: "/classified",
    settings: "/settings/classified",
    name: "Petites annonces",
    icon: ShoppingCart,
    component: Classified,
    componentSettings: ClassifiedSettings,
    layout: "/admin",
    id: module?.find(it => it.identifier === "classified").id,
    role: "classified",
  }, {
    path: "/links",
    settings: "/settings/links",
    name: "Liens",
    icon: Link,
    component: Links,
    componentSettings: LinksSettings,
    layout: "/admin",
    id: module?.find(it => it.identifier === "links").id,
    role: "links",
  }, {
    path: "/incidents",
    settings: "/settings/incidents",
    name: "Incidents",
    icon: ReportProblem,
    component: Incidents,
    componentSettings: IncidentsSettings,
    layout: "/admin",
    id: module?.find(it => it.identifier === "incident").id,
    role: "incident",
  }, {
    path: "/alerts",
    settings: null,
    name: "Bannières d'info",
    icon: AddAlert,
    component: Alerts,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "alerts",
  }, {
    path: "/mails",
    settings: null,
    name: "Mails",
    icon: Mail,
    component: Mails,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "mails",
    menuName: "Envoyer",
  }, {
    path: "/files",
    settings: null,
    name: "Documents",
    icon: Description,
    component: Files,
    componentSettings: null,
    layout: "/admin",
    id: -1,
    role: "files",
  }];
  
  return dashboardRoutes.filter(route => {//Filter the Menu items by user roles
    if ((route.id === -1 || siteModule?.find(it => it.features[0]?.module_id === route.id)) === false){
      return false;//Unidentified module id ?
    }
    if (route.role === null){
      return true;//No role needed ?
    }
    if (myself?.rights?.role_superadmin){
      return true;//Am I a super admin ?
    }
    try {
      if (myself?.rights[`role_${route.role}`]){
        return true;//Have I the right role ?
      }
    } catch (error){
      console.warn(error);
      return false;
    }
    return false;
  })

};

export default RoutesParser;
