import React, { useEffect } from "react";
import AdminPanelLogic from "./AdminPanelLogic";

import {
  Backdrop,
  CircularProgress,
  Button as MUIButton,
  makeStyles,
  Modal,
} from "@material-ui/core";
import Tabs from "components/CustomTabs/CustomTabs.js";

// Other front packages
import Table from "components/Table/Table.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import FormRoles from "components/FormUsers/FormRoles";
import FormAdmins from "components/FormUsers/FormAdmins";
import RoleDisplay from "components/RoleDisplay/RoleDisplay";
import Card from "components/Card/Card.js";
import ConfirmModal from "components/ConfirmModal";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Person from "@material-ui/icons/Person";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const useStyles = makeStyles(styles);

export default function AdminPanel(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    admins,
    adminDestroy,
    adminsCount,
    collectionsList,
    editModalOpened,
    editModalAdminOpened,
    editRoleInfo,
    formAdminValid,
    limitAdmins,
    myself,
    newAdminsInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    roles,
    deleteAdmin,
    handleFormAdminChange,
    handleFormChange,
    handlePagination,
    getTitle,
    parserRole,
    refreshData,
    resendPasswordAdmin,
    setOpenNotif,
    submitNewAdmin,
    submitRoleEdit,
    toggleConfirmAdminDestroy,
    toggleModal,
    toggleModalAdmin,
  } = AdminPanelLogic();

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (admins) {
    return (
      <div>
        <Snackbar
          place="tr"
          message={notifMessage}
          open={openNotif}
          close
          icon={
            notifColor === "success"
              ? CheckCircleIcon
              : notifColor === "danger"
              ? WarningIcon
              : NotificationsIcon
          }
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {editModalOpened && editRoleInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div
              className={classes.paper}
              style={{
                maxHeight: "80%",
              }}
            >
              <FormRoles
                collectionsList={collectionsList}
                editRoleInfo={editRoleInfo}
                myself={myself}
                handleFormChange={handleFormChange}
                getTitle={getTitle}
                submitRoleEdit={submitRoleEdit}
                toggleModal={toggleModal}
              />
            </div>
          </Modal>
        )}
        {adminDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmAdminDestroy(null)}
            open={adminDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmAdminDestroy(null)}
                kind={`Supprimer l'administrateur "${
                  adminDestroy.user?.first_name +
                  " " +
                  adminDestroy.user?.last_name
                }" ?`}
                makeAction={() => deleteAdmin(adminDestroy.id)}
              />
            </div>
          </Modal>
        )}
        {editModalAdminOpened && newAdminsInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModalAdmin(false)}
            open={editModalAdminOpened}
          >
            <div
              className={classes.paper}
              style={{
                maxHeight: "80%",
              }}
            >
              <FormAdmins
                formAdminValid={formAdminValid}
                newAdminsInfo={newAdminsInfo}
                handleFormAdminChange={handleFormAdminChange}
                submitNewAdmin={submitNewAdmin}
                toggleModalAdmin={toggleModalAdmin}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Admins",
                tabIcon: Person,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {myself?.rights?.role_superadmin && (
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <MUIButton
                              className={classes.button}
                              onClick={() => toggleModalAdmin()}
                              startIcon={<AddIcon />}
                            >
                              Ajouter un admin
                            </MUIButton>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    )}
                    <Card
                      style={{
                        boxShadow: "none",
                        marginTop: 0,
                        paddingTop: 0,
                      }}
                    >
                      {!myself?.rights?.role_superadmin &&
                      !myself?.rights?.role_edit_privilege ? (
                        <GridItem xs={12} sm={12} md={6}>
                          <h4>
                            {"Vous n'avez pas la permission de voir les admins"}
                          </h4>
                        </GridItem>
                      ) : (
                        <CardBody>
                          {adminsCount === 0 ? (
                            <h4>Aucun admin à afficher</h4>
                          ) : (
                            <Table
                              tableHeaderColor="primary"
                              tableType="user"
                              tableHead={["Admin", "Email"]
                                .concat(roles.map(role => role.title))
                                .concat([""])}
                              tableData={
                                admins &&
                                admins.map(admin => {
                                  const user = admin?.user;
                                  const userList = {
                                    user: `${user?.first_name ?? ""} ${user?.last_name ?? ""}`,
                                    admin: user?.email ?? "",
                                  };
                                  roles.forEach((role, i) => {
                                    const [
                                      globalRole,
                                      formattedRole,
                                    ] = parserRole(admin, role.key);
                                    userList[role.key] = (
                                      <RoleDisplay
                                        key={i}
                                        collectionsList={collectionsList}
                                        getTitle={getTitle}
                                        globalRole={globalRole}
                                        roles={formattedRole}
                                      />
                                    );
                                  });
                                  userList.actions = (
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      {(myself?.rights?.role_superadmin ||
                                        myself?.rights
                                          ?.role_edit_privilege) && (
                                        <Button
                                          style={{ marginLeft: "auto" }}
                                          color="warning"
                                          onClick={() => {
                                            toggleModal(admin);
                                          }}
                                          size="sm"
                                        >
                                          Modifier
                                        </Button>
                                      )}
                                      {myself?.rights?.role_superadmin && (
                                        <Button
                                          size="sm"
                                          style={{ marginLeft: "2%" }}
                                          onClick={() =>
                                            toggleConfirmAdminDestroy(admin)
                                          }
                                          color="danger"
                                        >
                                          Supprimer
                                        </Button>
                                      )}
                                      {myself?.rights?.role_superadmin && (
                                        <Button
                                          size="sm"
                                          style={{ marginLeft: "2%" }}
                                          onClick={() => {
                                            resendPasswordAdmin(admin?.user.uid);
                                          }}
                                          color="info"
                                        >
                                          Renvoyer un mot de passe
                                        </Button>
                                      )}
                                    </div>
                                  );
                                  return Object.values(userList);
                                })
                              }
                            />
                          )}
                          {adminsCount > 0 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 100,
                              }}
                            >
                              <Pagination
                                count={Math.ceil(adminsCount / limitAdmins)}
                                page={page}
                                onChange={(event, pageNumber) => {
                                  handlePagination(pageNumber);
                                }}
                                classes={{ ul: paginationClasses.ul }}
                                size="large"
                              />
                            </div>
                          )}
                        </CardBody>
                      )}
                    </Card>
                  </GridItem>
                ),
              },
            ]}
          />
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}
