import React, { useEffect } from "react";
import moment from "moment";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Pagination from "@material-ui/lab/Pagination";
import RefundsLogic from "../TabLogics/RefundsLogic";

import {makeStyles} from "@material-ui/core";

const listDateFormat = "DD MMMM YYYY [à] HH[h]mm";

const paginationStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "#6E6FA9",
		},
		"& .Mui-selected": {
			backgroundColor: "#6E6FA9",
			color: "#E7E6ED",
		},
	},
}));

export default function RefundsView(props) {
	
	const paginationClasses = paginationStyles();
	
	const {
		itemsCount,
		itemsList,
		limit,
		page,
		handlePagination,
		refreshData
	} = RefundsLogic();
	
	useEffect(() => {
		refreshData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	let rowNb = 1;
	
	return (
		<>
			<GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{itemsList && itemsList.length === 0 ? (
						<h4>Aucun remboursement enregistré</h4>
					) :
						(<Table
							tableHeaderColor="primary"
							tableType="transactions"
							tableHead={
								[
									"",
									"Statut",
									"Date",
									"Nom",
									"Montant"
								]
							}
							tableData={
								itemsList &&
								itemsList.map(item => {
									const amount = ((parseInt(item?.creditedAmount, 10) ?? 0) / 100).toFixed(2);
									let date = null;
									let formattedDate = null;
									let civility = item?.mpAuthor?.user?.call_title ?? "";
									civility = (civility === "MR" ? "M." : civility === "ML" ? "Mme." : "Mme.")
									let firstName = item?.mpAuthor?.user?.first_name ?? "";
									let lastName = item?.mpAuthor?.user?.last_name ?? "";
									const itemList = {
										rowId: `${rowNb + (limit * (page - 1))}`,
										status: item?.status ?? "---",
										date: moment(item?.executionDate).format(listDateFormat),
										author: `${civility} ${firstName} ${lastName}`,
										amount: `${amount}€`
									};
									rowNb += 1;
									return Object.values(itemList);
								})
							}
						/>)
					}
					{itemsCount > 0 && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								paddingTop: 100,
							}}
						>
							<Pagination
								count={Math.ceil(itemsCount / limit)}
								page={page}
								onChange={(event, pageNumber) => {
									handlePagination(pageNumber);
								}}
								classes={{ ul: paginationClasses.ul }}
								size="large"
							/>
						</div>
					)}
				</GridItem>
			</GridItem>
		</>
	)
}