import React, { useEffect } from "react";
// core components
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import GroupListView from './TabViews/GroupListView'
import GroupImportView from './TabViews/GroupImportView'
import { useDispatch, useSelector } from "react-redux";

export default function Groups(props) {
  
  const { myself } = useSelector(userState => userState.myself);
  let userCanEdit = false;
  try {
    if (myself?.rights?.role_superadmin === true || (myself?.rights?.role_groups === true && myself?.rights?.role_groups_edit === true)){
      userCanEdit = true;
    }
  } catch (error) {
    console.error(error)
  }
  
  let menus = [
    {
      tabName: "Appartements",
      tabIcon: AssignmentIcon,
      tabContent: <GroupListView />
    }
  ];
  if (userCanEdit){
    menus.push({
        tabName: "Importer des appartements",
        tabIcon: GroupAddIcon,
        tabContent: <GroupImportView />,
      })
  }

  return (
    <div>
      <GridContainer>
        <Tabs
          tabs={menus}
        />
      </GridContainer>
    </div>
  )
}