import {useEffect, useState} from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import {
		featureServices,
		moduleServices,
		reservationTypeServices,
		reservationCleaningServices,
		reservationLocationServices,
		reservationRessourceServices,
		reservationRuleServices,
		reservationSubscriptionServices
} from "services";

moment.updateLocale("fr", localization);

const ResourceListLogic = () => {
		const [refresh, setRefresh] = useState(false);

		const limits = {ressource: 5};

		const [openNotif, setOpenNotif] = useState(false);
		const [notifMessage, setNotifMessage] = useState("");
		const [notifColor, setNotifColor] = useState("info");
		const [refreshImageRessource, setRefreshImageRessource] = useState(undefined);

		const [ressourcesList, setRessourcesList] = useState(undefined);
		const [periodsList, setPeriodsList] = useState([]);
		const [ressourcesCount, setRessourcesCount] = useState(0);
		const [pageRessource, setPageRessource] = useState(1);
		const [ressourceRecurrence, setRessourceRecurrence] = useState([]);
		const [ressourceModalOpened, setRessourceModalOpened] = useState(false);
		const [newRessourceInfo, setNewRessourceInfo] = useState(undefined);
		const [modalRessourceType, setModalRessourceType] = useState(false);
		const [formRessourceValid, setFormRessourceValid] = useState(false);

		const [formRulesList, setFormRulesList] = useState(undefined);
		const [formCleaningList, setFormCleaningList] = useState(undefined);
		const [formModulesList, setFormModulesList] = useState(undefined);
		const [formLocationList, setFormLocationList] = useState(undefined);
		const [formTypeList, setFormTypeList] = useState(undefined);

		const [cleaningList, setCleaningList] = useState(undefined);
		const [cleaningCount, setCleaningCount] = useState(0);
		const [cleaningModalOpened, setCleaningModalOpened] = useState(false);

		const [bookingDestroy, setBookingDestroy] = useState(undefined);
		const [bookingDestroyType, setBookingDestroyType] = useState(undefined);
		
		const [formSectorList, setFormSectorList] = useState(undefined);

		const toggleConfirmBookingDestroy = (type, booking) => {
				setBookingDestroyType(type);
				setBookingDestroy(booking);
		};

		const deleteBooking = uuid => {
				deleteRessource(uuid);
				toggleConfirmBookingDestroy(null);
		};

		const modalMessageBooking = () => {
			return `Supprimer la ressource "${bookingDestroy.title}" ?`;
		};

		const refreshData = () => setRefresh(!refresh);

		useEffect(() => {
				(async () => {
						const ressources = await reservationRessourceServices.getAllRessources();
						if (ressources) {
								setRessourcesCount(ressources.length);
								const ress = ressources.slice(
										limits.ressource * (pageRessource - 1),
										limits.ressource * (pageRessource - 1) + limits.ressource
								);
								setRessourcesList(ress);
								if (!refreshImageRessource) {
										let r = {};
										ress.forEach(it => (r[it.uuid] = false));
										setRefreshImageRessource(r);
								}
								const recs = ressources
										.slice(
												limits.ressource * (pageRessource - 1),
												limits.ressource * (pageRessource - 1) + limits.ressource
										)
										.map(r => {
												let recurrence = r.weeks_availability;
												if (!r.weeks_availability) recurrence = "0000000";
												const days = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];
												const rec = [];
												for (let i in recurrence) {
														if (recurrence[i] === "1")
																rec.push({d: days[i], active: true});
														else rec.push({d: days[i], active: false});
												}
												return rec;
										});
								setRessourceRecurrence(recs);
						}
				})();
				// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
				pageRessource,
				refresh,
		]);

		const handlePaginationRessource = page => {
				setPageRessource(page);
		};

		const refreshImgRessource = uuid => {
				const refreshOn = {...refreshImageRessource};
				refreshOn[uuid] = true;
				setRefreshImageRessource(refreshOn);
				setTimeout(() => {
						const refreshOff = {...refreshImageRessource};
						refreshOff[uuid] = false;
						setRefreshImageRessource(refreshOff);
				}, 700);
		};

		const openNotification = (msg, color = "info", time = 6) => {
				setOpenNotif(false);
				setNotifMessage(msg);
				setNotifColor(color);
				setTimeout(() => setOpenNotif(false), time * 1000);
				setTimeout(() => setOpenNotif(true), 100);
		};

		const parseHourTime = time => {
				const t = time.split(":");
				if (t.length < 2) return time;
				return t[0] + ":" + t[1];
		};

		const getTitleInterval = val => {
				if (val === "nights") return "Nuit(s)";
				else if (val === "minutes") return "Minute(s)";
				else if (val === "hours") return "Heure(s)";
				else if (val === "days") return "Jour(s)";
				return "";
		};

		const getAllRessourceform = async () => {
				const module = await moduleServices.getAll();
				const feature = await featureServices.getAll();
				if (module && feature) {
						const share = module?.find(it => it.identifier === "share")?.id;
						if (share){
							setFormModulesList(feature.filter(it => it.module_id === share));
						}
				}
				const type = await reservationTypeServices.getAll();
				if (type) {
						setFormTypeList(type);
				}
				const cleaning = await reservationCleaningServices.getAll();
				if (cleaning) {
						setFormCleaningList(cleaning);
				}
				const location = await reservationLocationServices.getAll();
				if (location) {
						setFormLocationList(location);
				}
				const rule = await reservationRuleServices.getAll();
				if (rule) {
						setFormRulesList(rule);
				}
		};

		const addPeriodToRessource = (resourceUUID, timeLength, timeType, price) => {
				const data = {
						period_number: timeLength,
						period_type: timeType,
						price,
				};
				return reservationSubscriptionServices.addPeriod(resourceUUID, data);
		};

		const updatePeriodFromRessource = (uuid, timeLength, timeType, price) => {
				const data = {
						period_number: timeLength,
						period_type: timeType,
						price,
				};
				return reservationSubscriptionServices.updatePeriod(uuid, data);
		};

		const deletePeriodFromRessource = uuid => {
				return reservationSubscriptionServices.deletePeriod(uuid);
		};

		const toggleModalRessource = async (ressource = null) => {
				getAllRessourceform();
				setFormRessourceValid(false);
				setModalRessourceType(ressource ? true : false);
				const ressourceInfo = {
						uuid: ressource?.uuid,
						title: ressource?.title ? ressource?.title : "",
						enabled:
								typeof ressource?.enabled === "boolean" ? ressource?.enabled : true,
						weeks_availability: ressource?.weeks_availability
								? ressource?.weeks_availability
								: "0000000",
						available_months: ressource?.available_months
								? ressource?.available_months
								: 0,
						available_days: ressource?.available_days
								? ressource?.available_days
								: 0,
						interval_value: ressource?.interval_value ? ressource?.interval_value : 1,
						interval_type: ressource?.interval_type
								? ressource?.interval_type
								: "days",
						interval_unified: ressource?.interval_unified
								? ressource?.interval_unified
								: false,
						open_at: ressource?.open_at ? parseHourTime(ressource?.open_at) : "",
						close_at: ressource?.close_at ? parseHourTime(ressource?.close_at) : "",
						booking_price: parseFloat(
								ressource?.booking_price ? ressource?.booking_price / 100 : 0
						).toFixed(2),
						unit_price: parseFloat(
								ressource?.unit_price ? ressource?.unit_price / 100 : 0
						).toFixed(2),
						unit_min: ressource?.unit_min ? ressource?.unit_min : 1,
						unit_max: ressource?.unit_max ? ressource?.unit_max : 1,
						available_units: ressource?.available_units
								? ressource?.available_units
								: 1,
						unit_self_delay: ressource?.unit_self_delay
								? ressource?.unit_self_delay
								: 0,
						unit_users_delay: ressource?.unit_users_delay
								? ressource?.unit_users_delay
								: 0,
						booking_method: ressource?.booking_method && ressource?.booking_method,
						quota: ressource?.quota ? ressource?.quota : "",
						max_rolling_bookings: (ressource?.max_rolling_bookings !== null && ressource?.max_rolling_bookings !== undefined)? ressource?.max_rolling_bookings : "",
						is_group_access: !!ressource?.is_group_access,
						warnAdmin: ressource?.warnAdmin ? ressource?.warnAdmin : false,
						custom_message: ressource?.custom_message
								? ressource?.custom_message
								: "",
						cancel_delay_hr: ressource?.cancel_delay_hr ? ressource?.cancel_delay_hr : 0,
						booking_delay_hr: ressource?.booking_delay_hr ? ressource?.booking_delay_hr : 0,
						cleaning: ressource?.cleaning?.uuid ? ressource?.cleaning?.uuid : "",
						location: ressource?.location?.uuid ? ressource?.location?.uuid : "",
						rule: ressource?.rule?.uuid ? ressource?.rule?.uuid : "",
						type: ressource?.type?.keyid ? ressource?.type?.keyid : "",
						features: ressource?.features
								? ressource?.features.map(it => it.uuid)
								: [],
						image: null,
						has_picture: ressource?.has_picture ? ressource?.has_picture : false,
				};
				if (ressourceInfo.uuid) {
						const periods = await reservationSubscriptionServices.getAllPeriod(ressourceInfo.uuid)
						if (periods.length) {
								setPeriodsList(periods)
						}
				}
				setNewRessourceInfo(ressourceInfo);
				setRessourceModalOpened(!ressourceModalOpened);
		};

		const submitNewRessource = async event => {
				event.preventDefault();
				const ressourceInfo = {...newRessourceInfo};
				ressourceInfo.open_at = !ressourceInfo.open_at?.length
						? ""
						: ressourceInfo.open_at + ":00";
				ressourceInfo.close_at = !ressourceInfo.close_at?.length
						? ""
						: ressourceInfo.close_at + ":00";
				ressourceInfo.booking_price = ressourceInfo.booking_price * 100;
				ressourceInfo.unit_price = ressourceInfo.unit_price * 100;
				ressourceInfo.unit_min = parseInt(ressourceInfo.unit_min);
				ressourceInfo.unit_max = parseInt(ressourceInfo.unit_max);
				ressourceInfo.available_units = parseInt(ressourceInfo.available_units);
				ressourceInfo.unit_self_delay = parseInt(ressourceInfo.unit_self_delay);
				ressourceInfo.unit_users_delay = parseInt(ressourceInfo.unit_users_delay);
				ressourceInfo.booking_delay_hr = parseInt(ressourceInfo.booking_delay_hr);
				ressourceInfo.cancel_delay_hr = parseInt(ressourceInfo.cancel_delay_hr);
				ressourceInfo.available_months = parseInt(ressourceInfo.available_months);
				ressourceInfo.interval_value = parseInt(ressourceInfo.interval_value);
				let ressource = undefined;
				if (newRessourceInfo.uuid) {
						const features = ressourcesList.find(
								it => it.uuid === newRessourceInfo.uuid
						).features;
						features
								.filter(
										feature => !ressourceInfo.features.find(it => it === feature.uuid)
								)
								.forEach(
										async it =>
												await reservationRessourceServices.detachFeature(
														newRessourceInfo.uuid,
														it.uuid
												)
								);
						ressourceInfo.features
								.filter(it => !features.find(feature => feature.uuid === it))
								.forEach(
										async it =>
												await reservationRessourceServices.attachFeature(
														newRessourceInfo.uuid,
														it
												)
								);

						ressource = await reservationRessourceServices.update(
								newRessourceInfo.uuid,
								ressourceInfo
						);
						if (ressource) {
								openNotification(
										`La ressource ${ressource?.title} a été modifié`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de la modification de la ressource ${newRessourceInfo?.title}`,
										"danger"
								);
						}
				} else {
						ressource = await reservationRessourceServices.add(ressourceInfo);
						if (ressource) {
								for (let x of ressourceInfo.features) {
										await reservationRessourceServices.attachFeature(ressource.uuid, x);
								}
								openNotification(
										`La ressource ${ressource?.title} a été ajouté`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de l'ajout de la ressource ${newRessourceInfo?.title}`,
										"danger"
								);
						}
				}
				if (ressource) {
						await reservationRessourceServices.setTypeForRessource(
								ressourceInfo.type?.length ? ressourceInfo.type : null,
								ressource.uuid
						);
						await reservationRessourceServices.setLocationForRessource(
								ressourceInfo.location?.length ? ressourceInfo.location : null,
								ressource.uuid
						);
						await reservationRessourceServices.setRuleForRessource(
								ressourceInfo.rule?.length ? ressourceInfo.rule : null,
								ressource.uuid
						);
						await reservationRessourceServices.setCleaningForRessource(
								ressourceInfo.cleaning?.length ? ressourceInfo.cleaning : null,
								ressource.uuid
						);
				}
				if (newRessourceInfo.image && ressource) {
						let data = new FormData();
						data.append("file", newRessourceInfo.image);
						await reservationRessourceServices.setImage(ressource.uuid, data);
						refreshImgRessource(ressource.uuid);
				}
				refreshData();
				setRessourceModalOpened(!ressourceModalOpened);
				setFormRessourceValid(true);
		};

		const handleRessourceFormChange = (key, value, idx = 0) => {
				const ressourceInfo = {...newRessourceInfo};
				if (key === "recurrence") {
						ressourceInfo.weeks_availability =
								ressourceInfo.weeks_availability.substring(0, idx) +
								(value ? "1" : "0") +
								ressourceInfo.weeks_availability.substring(idx + 1);
				} else {
						ressourceInfo[key] = value;
				}
				setNewRessourceInfo(ressourceInfo);
				isFormRessourceValid(ressourceInfo);
		};

		const isFormRessourceValid = ressource => {
				let valid = true;
				let state = 0;
				if (ressource?.quota?.length) {
						for (let i of ressource?.quota) {
								if (state === 4) {
										valid = false;
										break;
								}
								if (state === 1 && i === "T") state = 2;
								else if (state === 3 && (i === "D" || i === "M" || i === "Y")) state = 4;
								else if (state <= 3 && isNaN(parseInt(i))) {
										valid = false;
										break;
								} else if (state === 0 && !isNaN(parseInt(i))) state = 1;
								else if (state === 2 && !isNaN(parseInt(i))) state = 3;
						}
						if (state !== 4) valid = false;
				}
				
				const isLessThanZero = (prop) => {
						return parseInt(prop) < 0
				}
				
				const isLessThanOne = (prop) => {
						return parseInt(prop) < 1
				}
				
				const isLessThanMinusOne = (prop) => {
						return parseInt(prop) < -1
				}
				
				const isNotANumber = (prop) => {
						return isNaN(parseInt(prop));
				}
				
				const isNotEmpty = (prop) => {
						if (typeof prop === "string"){
								return prop.length > 0;
						}
						if (typeof prop === "number"){
								return 1;
						}
						return 0;
				}
				
				if (!valid){
						console.warn("Ressource invalid");
						setFormRessourceValid(false);
				}else if (!ressource?.title?.length){
						console.warn("Title must be set");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.available_months) || isLessThanZero(ressource?.available_months)){
						console.warn("Invalid available_months field");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.available_days) || isLessThanZero(ressource?.available_days)){
						console.warn("Invalid available_days field");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.interval_value) || isLessThanOne(ressource?.interval_value)) {
						console.warn("Invalid interval_value field");
						setFormRessourceValid(false);
				}else if (!["days", "nights", "hours", "minutes"].find(it => it === ressource?.interval_type)) {
						console.warn("Invalid interval_type field");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.unit_min) || isLessThanOne(ressource?.unit_min)) {
						console.warn("Invalid unit_min field");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.unit_max) || isLessThanOne(ressource?.unit_max)) {
						console.warn("Invalid unit_max field");
						setFormRessourceValid(false);
				}else if (ressource?.unit_max < ressource?.unit_min) {
						console.warn("unit_max must be greater or egal to unit_min");
						setFormRessourceValid(false);
				}else if (isNotEmpty(ressource?.max_rolling_bookings) && isNotANumber(ressource?.max_rolling_bookings)) {
						console.warn("Invalid max_rolling_bookings field");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.available_units) || isLessThanOne(ressource?.available_units)){
						console.warn("Invalid available_units field");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.unit_self_delay) || isLessThanZero(ressource?.unit_self_delay)){
						console.warn("Invalid unit_self_delay field");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.unit_users_delay) || isLessThanZero(ressource?.unit_users_delay)){
						console.warn("Invalid unit_users_delay field");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.cancel_delay_hr) || isLessThanZero(ressource?.cancel_delay_hr)) {
						console.warn("Invalid cancel_delay_hr field");
						setFormRessourceValid(false);
				}else if (isNotANumber(ressource?.booking_delay_hr) || isLessThanMinusOne(ressource?.booking_delay_hr)) {
						console.warn("Invalid booking_delay_hr field");
						setFormRessourceValid(false);
				}else{
						setFormRessourceValid(true);
				}
		};

		const deleteRessource = async uuid => {
				await reservationRessourceServices.destroy(uuid);
				openNotification(
						`La ressource ${
								ressourcesList.find(it => it.uuid === uuid)?.title
						} a été supprimé`,
						"success"
				);
				refreshData();
		};

		return {
				bookingDestroy,
				cleaningCount,
				cleaningList,
				cleaningModalOpened,
				formCleaningList,
				formLocationList,
				formModulesList,
				formRessourceValid,
				formRulesList,
				formSectorList,
				formTypeList,
				limits,
				modalRessourceType,
				newRessourceInfo,
				notifColor,
				notifMessage,
				openNotif,
				pageRessource,
				periodsList,
				refresh,
				refreshImageRessource,
				ressourcesCount,
				ressourcesList,
				ressourceRecurrence,
				ressourceModalOpened,
				addPeriodToRessource,
				updatePeriodFromRessource,
				deletePeriodFromRessource,
				deleteBooking,
				getTitleInterval,
				handlePaginationRessource,
				handleRessourceFormChange,
				modalMessageBooking,
				parseHourTime,
				refreshData,
				setOpenNotif,
				setPeriodsList,
				submitNewRessource,
				toggleConfirmBookingDestroy,
				toggleModalRessource,
		};
};

export default ResourceListLogic;
