import { useEffect, useState } from "react";

import {
  classifiedCategoryServices,
  classifiedSettingServices,
  moduleServices,
  featureServices,
} from "services";

const ClassifiedSettingsLogic = () => {

  const [refresh, setRefresh] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [categoriesList, setCategoriesList] = useState(undefined)
  const [settingsList, setSettingsList] = useState(undefined)

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newSettingsInfo, setNewSettingsInfo] = useState(undefined);
  const [formClassifiedList, setFormClassifiedList] = useState(undefined)
  const [formMessagesList, setFormMessagesList] = useState(undefined)
  const [modalType, setModalType] = useState(false);

  const [settingDestroy, setSettingDestroy] = useState(undefined);

  const toggleConfirmSettingDestroy = setting => setSettingDestroy(setting);

  useEffect(() => {
    (async () => {
      const category = await classifiedCategoryServices.getAll()
      if (category) {
        setCategoriesList(category)
      }
      const settings = await classifiedSettingServices.getFeatureRelations()
      if (settings) {
        setSettingsList(settings)
      }
    })();
  }, [refresh]);

  const refreshData = () => setRefresh(!refresh);

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const handleChangeCategory = (e, category) => {
    const newCategoryList = categoriesList.slice(0)
    newCategoryList.find(it => it.id === category.id).activated = e.target.checked
    setCategoriesList(newCategoryList)
  }

  const submitCategoriesSettings = async () => {
    const allCategories = await classifiedCategoryServices.fetchForSettings()
    if (allCategories) {
      allCategories.map(async (it) => {
        const category = categoriesList.filter(c => c.id === it.id && c.activated !== it.activated)
        if (category?.length) {
          if (category[0].activated)
            await classifiedCategoryServices.activateForSettings(category[0].uuid)
          else
            await classifiedCategoryServices.deactivateForSettings(category[0].uuid)
        }
      })
      openNotification(`Les paramètres des catégories ont été enregistré`, "success")
    } else {
      openNotification("Une erreur est survenu lors de la validation des paramètres des catégories", "danger")
    }
  }

  const getAllRessourceform = async () => {
    const module = await moduleServices.getAll();
    const feature = await featureServices.getAll();
    if (module && feature) {
      const classified = module?.find(it => it.identifier === "classified")?.id;
      if (classified){
        setFormClassifiedList(feature.filter(it => it.module_id === classified))
      }
      const message = module?.find(it => it.identifier === "message")?.id;
      if (message){
        setFormMessagesList(feature.filter(it => it.module_id === message))
      }
    }
  }

  const toggleModal = async (setting = null) => {
    getAllRessourceform()
    setModalType(setting ? true : false);
    const settingsInfo = {
      id: setting?.id,
      classified: setting?.classified_feature?.uuid ? setting?.classified_feature?.uuid : '',
      message: setting?.message_feature?.uuid ? setting?.message_feature?.uuid : '',
    };
    setNewSettingsInfo(settingsInfo);
    setEditModalOpened(!editModalOpened);
  };

  const handleFormChange = (key, value) => {
    let settingsInfo = { ...newSettingsInfo };
    settingsInfo[key] = value;
    setNewSettingsInfo(settingsInfo);
  };

  const submitNewSettings = async event => {
    event.preventDefault();
    if (settingsList.find(it => it?.classified_feature?.uuid === newSettingsInfo.classified && it?.message_feature?.uuid === newSettingsInfo.message)) {
      openNotification("Vous ne pouvez pas créer cette relation, elle existe déjà !", "danger")
    } else {
      const classified = formClassifiedList.find(it => it.uuid === newSettingsInfo?.classified)
      const message = formMessagesList.find(it => it.uuid === newSettingsInfo?.message)
      if (newSettingsInfo?.id) {
        if (await classifiedSettingServices.update(newSettingsInfo?.id, newSettingsInfo.classified, newSettingsInfo.message)) {
          openNotification(`La relation ${classified?.custom_title} - ${message?.custom_title} a été modifié`, "success")
        } else {
          openNotification(`Erreur, une erreur est survenu lors de la modification de la relation ${classified?.custom_title} - ${message?.custom_title}`, 'danger')
        }
      } else {
        if (await classifiedSettingServices.setFeatureRelations(newSettingsInfo.classified, newSettingsInfo.message)) {
          openNotification(`La relation ${classified?.custom_title} - ${message?.custom_title} a été ajouté`, "success")
        } else {
          openNotification(`Erreur, une erreur est survenu lors de l'ajout de la relation ${classified?.custom_title} - ${message?.custom_title}`, 'danger')
        }
      }
      setEditModalOpened(!editModalOpened);
      refreshData();
    }
  };

  const deleteSetting = async id => {
    const setting = settingsList.find(it => it.id === id)
    await classifiedSettingServices.destroy(id)
    openNotification(`La relation ${setting?.classified_feature?.custom_title} - ${setting?.message_feature?.custom_title} a été supprimé`, 'success')
    toggleConfirmSettingDestroy(null)
    refreshData()
  };

  return {
    editModalOpened,
    categoriesList,
    formClassifiedList,
    formMessagesList,
    modalType,
    newSettingsInfo,
    notifColor,
    notifMessage,
    openNotif,
    settingDestroy,
    settingsList,
    deleteSetting,
    handleChangeCategory,
    handleFormChange,
    refreshData,
    setOpenNotif,
    submitCategoriesSettings,
    submitNewSettings,
    toggleModal,
    toggleConfirmSettingDestroy,
  }
}

export default ClassifiedSettingsLogic