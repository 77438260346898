import React from "react";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

// core components
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridContainer from "components/Grid/GridContainer.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import BuildingListView from './TabViews/BuildingListView';
import BuildingImportView from './TabViews/BuildingImportView';
import { useDispatch, useSelector } from "react-redux";

export default function Attributes(props) {
  
  const { myself } = useSelector(userState => userState.myself);
  let userCanEdit = false;
  try {
    if (myself?.rights?.role_superadmin === true || (myself?.rights?.role_sectors === true && myself?.rights?.role_sectors_edit === true)){
      userCanEdit = true;
    }
  } catch (error) {
    console.error(error)
  }
  
  let menus = [
    {
      tabName: "Bâtiments",
      tabIcon: AssignmentIcon,
      tabContent: <BuildingListView />
    }
  ];
  if (userCanEdit){
    menus.push({
      tabName: "Importer des bâtiments",
      tabIcon: GroupAddIcon,
      tabContent: <BuildingImportView />
    })
  }
  

  return (
    <div>
      <GridContainer>
        <Tabs
          tabs={menus}
        />
      </GridContainer>
    </div>
  )
}