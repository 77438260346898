import { useEffect, useState } from "react";
import { transactionServices } from "services";

const BankAccountsLogic = () => {

	const [refresh, setRefresh] = useState(false);
	const [items, setItems] = useState([]);

	useEffect(() => {
		(async () => {
			const responseData = await transactionServices.getIBANBankAccounts();
			if (responseData) {
				setItems(responseData)
			}
		})();
	}, [refresh]);

	const refreshData = () => setRefresh(!refresh);
	
	return {
		items,
		refreshData,
	}
}

export default BankAccountsLogic