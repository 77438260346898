import React, { useEffect } from "react";
import moment from "moment";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Pagination from "@material-ui/lab/Pagination";
import PayinsLogic from "../TabLogics/PayinsLogic";
//import LinkIcon from '@material-ui/icons/Link';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
	Link
} from "@material-ui/core";

import {makeStyles} from "@material-ui/core";

const listDateFormat = "DD MMMM YYYY [à] HH[h]mm";

const paginationStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "#6E6FA9",
		},
		"& .Mui-selected": {
			backgroundColor: "#6E6FA9",
			color: "#E7E6ED",
		},
	},
}));

export default function PayinsView(props) {
	
	const paginationClasses = paginationStyles();
	
	const {
		itemsCount,
		itemsList,
		limit,
		page,
		handlePagination,
		refreshData
	} = PayinsLogic();
	
	useEffect(() => {
		refreshData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);

	let rowNb = 1;
	
	return (
		<>
			<GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{itemsList && itemsList.length === 0 ? (
						<h4>Aucun paiement enregistré</h4>
					) :
						(<Table
							tableHeaderColor="primary"
							tableType="transactions"
							tableHead={
								[
									"",
									"Statut",
									"Date",
									"Nom",
									"Montant",
									"Remboursé",
									"Code erreur"
								]
							}
							tableData={
								itemsList &&
								itemsList.map(item => {
									const amount = ((parseInt(item?.debited_funds_amount, 10) ?? 0) / 100).toFixed(2);
									let civility = item?.author?.user?.call_title ?? "";
									civility = (civility === "MR" ? "M." : civility === "ML" ? "Mme." : "Mme.")
									const firstName = item?.author?.user?.first_name ?? "";
									const lastName = item?.author?.user?.last_name ?? "";
									const didRefund = item?.mpRefund ? "Oui" : "Non";
									const errorCode = item?.result_code ?? ""
									const errorCodeLink = `https://docs.mangopay.com/errors/codes/${errorCode}`;
									const itemList = {
									  rowId: `${rowNb + (limit * (page - 1))}`,
										status: item?.status ?? "---",
										date: moment(item?.created_at).format(listDateFormat),
										author: `${civility} ${firstName} ${lastName}`,
										amount: `${amount}€`,
										refunded: item?.status != "FAILED" ? didRefund : "---",
										code: item?.status == "FAILED" ? <a target="_blank" href={errorCodeLink}>{errorCode}</a> ?? "---" : '---'
									};
									rowNb += 1;
									return Object.values(itemList);
								})
							}
						/>)
					}
					{itemsCount > 0 && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								paddingTop: 100,
							}}
						>
							<Pagination
								count={Math.ceil(itemsCount / limit)}
								page={page}
								onChange={(event, pageNumber) => {
									handlePagination(pageNumber);
								}}
								classes={{ ul: paginationClasses.ul }}
								size="large"
							/>
						</div>
					)}
				</GridItem>
			</GridItem>
			<GridItem xs={12} sm={12} md={12}>
				<div style={{textAlign:"center", marginTop: 50}} >
					<Link target="_blank" rel="noopener" href="https://docs.mangopay.com/errors/codes">
						<>
							<OpenInNewIcon /><span>Comprendre les codes d'erreur</span>
						</>
					</Link>
				</div>
			</GridItem>
		</>
	)
}